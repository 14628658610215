import * as types from "./mutation-types";

function actionCommonHttp(sourceUrl, params, method = "get") {
  return new Promise(function (resolve, reject) {
    dsf.http[method](sourceUrl, params)
      .done(res => {
        resolve(res);
      })
      .error(err => {
        reject(err);
      });
  });
}

export const playerPlaySaveProgress = function ({ commit }, query) {
  const { courseId, coursewareId, watchPoint, pulseTime, pulseRate } = query;
  const sourceUrl = "nc/course/front/pulseSaveRecord";
  const params = {
    courseId,
    coursewareId,
    watchPoint,
    pulseTime,
    pulseRate,
  };
  actionCommonHttp(sourceUrl, params, "post")
    .then(res => {
      !res.success && dsf.layer.pc.message("保存学习进度失败,请重新登录或联系管理员", false);
      res.success && commit(types.SET_CURRENT_PLAY_PROGRESS, res.data.finishedRate);
    })
    .catch(err => {
      console.error("保存进度失败");
    });
};

export const uploadScreenshot = function ({ commit }, query) {
  const sourceUrl = "file/base64Img";
  const params = {
    base64ImgStr: query.substring(23),
  };
  actionCommonHttp(sourceUrl, params, "post")
    .then(res => {
      res.success && commit(types.PLAYER_SET_SCRRENSHOT, res.data);
    })
    .catch(err => {
      console.error("上传视频截图");
    });
};

export const specialApplyInfogz = ({ dispatch, commit }, query) => {
  // 专题班报名信息
  const { data, id } = query;
  const sourceUrl = "/nc/class/apply/info";
  const params = { id };
  actionCommonHttp(sourceUrl, params, "get")
    .then(res => {
      res.success && commit(types.Set_Special_Apply_Info_gz, res.data);
      dispatch("specialApplygz", { id: res.data.classId });
    })
    .catch(err => {
      console.error(this, "失败==》", err);
    });
};
// export const specialApplyInfos = function ({ commit }, query) {
//   // 专题班报名信息
//   const { data, id } = query;
//   const sourceUrl = "/nc/class/apply/info";
//   const params = { id };
//   actionCommonHttp(sourceUrl, params, "get")
//     .then(res => {
//       res.success && commit(types.Set_Special_Apply_Infos, res.data);
//     })
//     .catch(err => {
//       console.error(this, "失败==》", err);
//     });
// }

// export const specialApplys = function ({ commit }, query) {
//   //专题班报名
//   const { data, id } = query;
//   const sourceUrl = "nc/class/enroll";
//   const params = data ? Object.assign({}, data, { id }) : { id };
//   actionCommonHttp(sourceUrl, params, "post")
//     .then(res => {
//       res.success && commit(types.Set_Special_Applys, res);
//       dsf.layer.message(res.message);
//     })
//     .catch(err => {
//       console.error(this, "失败==》", err);
//     });
// };

export const specialApplygz = ({ dispatch, commit }, query) => {
  //专题班报名
  const { data, id } = query;
  const sourceUrl = "nc/class/enroll";
  const params = data ? Object.assign({}, data, { id }) : { id };
  return new Promise((reslove, reject) => {
    actionCommonHttp(sourceUrl, params, "post")
    .then(res => {
      // dispatch("specialApplyInfogz", { id: id });
      res.success && commit(types.Set_Special_Apply_Gz, res);
      //20002为班导进入自己的班级，不进行提示
      if (res.state != 20002) {
        dsf.layer.message(res.message);
      }
      reslove(res)
    })
    .catch(err => {
      console.error(this, "失败==》", err);
      reject(err)
    });
  })
};
export const getunreadCount = function ({ commit }, query) {
  // 获取公告
  const id = location.hash.split("id=")[1].split("&")[0];
  const sourceUrl = "/nc/class/train/notice/unreadCount";
  const params = { id };
  actionCommonHttp(sourceUrl, params, "get")
    .then(res => {
      res.success && commit(types.Set_Unread_Count, res.data);
    })
    .catch(err => {
      console.error(this, "失败==》", err);
    });
};

export const getState = function ({ commit }, query) {
  // 获取报名状态
  const id = location.hash.split("id=")[1].split("&")[0];
  const sourceUrl = "/nc/class/train/info";
  const params = { id };
  return new Promise(resolve => {
    actionCommonHttp(sourceUrl, params, "get")
      .then(res => {
        res.success && commit(types.Set_Course_State, res.data);
        resolve(res.data);
      })
      .catch(err => {
        console.error(this, "失败==》", err);
      });
  });
};
export const getState2 = function ({ commit }, query) {
  // 获取报名状态
  const id = location.hash.split("id=")[1].split("&")[0];
  const sourceUrl = "/nc/class/train/info";
  const params = { id };
  actionCommonHttp(sourceUrl, params, "get")
    .then(res => {
      res.success && commit(types.Set_Course_State, res.data);
    })
    .catch(err => {
      console.error(this, "失败==》", err);
    });
};
export const getIsStudent = function ({ commit }, query) {
  let isstudent;
  // 获取用户身份状态
  const classId = location.hash.split("id=")[1].split("&")[0];
  const sourceUrl = "/nc/class/train/community/queryStudentType";
  const params = { classId };
  actionCommonHttp(sourceUrl, params, "get")
    .then(res => {
      if (!res.data) {
        isstudent = true;
      } else {
        isstudent = false;
      }
      // res.success && commit(types.Set_IS_Student, isstudent);
      res.success && commit(types.Set_IS_Student, res.data);
      // res.success && commit(types.Set_IS_Student, 0);
    })
    .catch(err => {
      // console.error(this, "失败==》", err);
    });
};

export const getBasicTrain = function ({ commit }, params) {
  // 获取班级是否为基础培训
  const sourceUrl = "/nc/basetrain/checkIsBaseTrain";
  return new Promise(function (resolve, reject) {
    dsf.http['get'](sourceUrl, params)
      .done(res => {
        res.success && commit(types.Set_IS_BASICTRAIN, res.data ? res.data.result : false);
        resolve(res);
      })
      .error(err => {
        reject(err);
      });
  });
};
