export const SET_CURRENT_PLAY_ITEM = "SET_CURRENT_PLAY_ITEM"; //当前播放课件
export const SET_CURRENT_PLAY_TIME = "SET_CURRENT_PLAY_TIME"; //当前播放器播放时间
export const SET_CURRENT_PLAY_PROGRESS = "SET_CURRENT_PLAY_PROGRESS"; //当前播放课件进度
export const SET_CURRENT_PLAY_SEEKTIME = "SET_CURRENT_PLAY_SEEKTIME"; //定位当前播放位置
export const PLAYER_HAS_PLAY = "PLAYER_HAS_PLAY";
export const PLAYER_SET_SCRRENSHOT = "PLAYER_SET_SCRRENSHOT"; //播放器视频截图

export const Set_Special_Apply_Gz = "Set_Special_Apply_Gz"; // 判断是否报名
// export const Set_Special_Apply_Infos = "Set_Special_Apply_Infos"; //获取报名信息
export const Set_Special_Apply_Info_gz = "Set_Special_Apply_Info_gz"; //获取报名信息
// const Set_Special_Apply_Info = "Set_Special_Apply_Info"; //获取报名信息
export const Set_Unread_Count = "Set_Unread_Count"; //获取报名信息
export const Set_Course_State = "Set_Course_State"; //获取报名状态
export const Set_IS_Student = "Set_IS_Student"; //获取报名状态
export const Set_IS_BASICTRAIN = "Set_IS_BASICTRAIN"; //基础培训
