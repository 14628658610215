// export const curPlayItem = state => state.curPlayItem;
// export const screenshot = state => state.screenshot;
// export const curPlayTime = state => state.curPlayTime;
// export const curPlayProgress = state => state.curPlayProgress;
// export const seektime = state => state.seektime;
export const userName = state => state.userName;
export const getSpecialInfoGz = state => {
  console.log(state.specialInfo);
  return state.specialInfo;
};
export const getApplyStateGz = state => {
  return state.applyStateGz;
};
export const getcoursestate = state => {
  return state.coursestate;
};

export const isBasicTrain = state => {
  return state.isBasicTrain;
};

// export const getSpecialApplyStates = state => state.applyStates;
// export const getSpecialApplyInfors = state => state.applyInfos;
